const _ = require("lodash");
const apiURL = "https://api.smash.gg/gql/alpha";

const getUsersTournamentsQuery = `query TournamentsByOwner {
  currentUser{
    id,
    tournaments{
      nodes{
        name
        admins{
          id
        }
        events{
          name
          id
        }
      }
    }
  }
}`;

const getCurrentUserQuery = `query currentUserQuery {
  currentUser{
    id,
  }
}`;

const getEventByIDQuery = `query Event($id: ID!) {
  event(id: $id) {
    id
    name
    tournament {
      name
      streams{
        streamName
      }
      admins{
        id
      }
      streamQueue{
        id
        sets{
          id
          fullRoundText
          event{
            id
          }
        slots {
          id
          standing{
            stats{
              score {
                value
              }
            }
          }
          entrant {
            id
            name
            participants{
              player{
                id
                gamerTag
                prefix
              }
            }
          }
        }
        }
        stream {
          streamId
          streamName
        }
      }
    }
    entrants{
      nodes{
        participants{
          player{
            id
            gamerTag
            prefix
          }
        }
      }
    }
  }
}`;

const getCurrentUser = async (user_token) => {
  let json = await fetchQuery(getCurrentUserQuery, user_token, {});
  return json.data.currentUser;
};

const getCurrentUserEvents = async (user_token) => {
  let json = await fetchQuery(getUsersTournamentsQuery, user_token, {});
  return {
    nodes: json.data.currentUser.tournaments.nodes.filter(
      (node) => node.admins !== null
    ),
  };
};

const getEventParticipants = async (user_token, id) => {
  let json = await fetchQuery(getEventByIDQuery, user_token, { id });
  let event_participants = {};
  json.data.event.entrants.nodes.forEach((entrant) => {
    entrant.participants.forEach((participant) => {
      event_participants[participant.player.id] = participant.player;
    });
  });
  return Object.values(event_participants);
};

const getEventByID = async (user_token, id) => {
  let json = await fetchQuery(getEventByIDQuery, user_token, { id });
  return json.data.event;
};

const fetchQuery = async (query, user_token, variables) => {
  let data = await fetch(apiURL, {
    method: "post",
    headers: new Headers({
      Authorization: `Bearer ${user_token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  return await data.json();
};

const getBracketUpdateListener = (id, user_token, onUpdate, frequency) => {
  let listener = new QueryListener(
    getEventByIDQuery,
    user_token,
    { id },
    frequency
  );
  listener.listen((json) => {
    let event_participants = {};
    let stream_queues = {};
    let streams = {};
    if (json.data.event.entrants.nodes) {
      json.data.event.entrants.nodes.forEach((entrant) => {
        entrant.participants.forEach((participant) => {
          event_participants[participant.player.id] = participant.player;
        });
      });
    }
    if (json.data.event.tournament.streamQueue) {
      json.data.event.tournament.streamQueue.forEach((stream_queue) => {
        stream_queues[stream_queue.stream.streamName] =
          stream_queue.sets.filter(
            (set) => set.event.id === json.data.event.id
          );
      });
    }
    if (json.data.event.tournament.streams) {
      json.data.event.tournament.streams.forEach((stream) => {
        streams[stream.streamName] = stream_queues[stream.streamName] ?? [];
      });
    }
    onUpdate({
      event: json.data.event,
      participants: Object.values(event_participants),
      stream_queues: streams,
    });
  });
  return listener;
};

class QueryListener {
  constructor(query, user_token, variables, frequency) {
    this.callbacks = [];
    this.previous_data = null;
    this.query = query;
    this.user_token = user_token;
    this.variables = variables;
    this.getData();
    this.timer = setInterval(this.getData, frequency);
  }

  getData = async () => {
    let data = await fetchQuery(this.query, this.user_token, this.variables);
    if (!_.isEqual(this.previous_data, data)) {
      this.previous_data = data;
      this.callbacks.forEach((callback) => {
        callback(data);
      });
    }
  };

  listen = (onUpdate) => {
    this.callbacks.push(onUpdate);
  };

  close = () => {
    clearInterval(this.timer);
  };
}

export {
  getCurrentUserEvents,
  getEventByID,
  getEventParticipants,
  getBracketUpdateListener,
};

import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { getEventParticipants } from '../../../smash-gg'

class ParticipantSelect extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    bracket: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]).isRequired,
  };

  static defaultProps = {
    placeholder: '',
  };

  handleChange = (selectedOption) => {
    const { name, onChange } = this.props;
    onChange({
      target: {
        name,
        value: selectedOption.value,
        label: selectedOption.label,
      },
    });
  };

  getOptions = (data) => {
    const options = [];
    data.forEach((participant) => {
      const { id } = participant;
      const displayName = participant.gamerTag;
      options.push({
        value: id,
        label: displayName,
      });
    });
    return options;
  }

  render() {
    const {
      value, name, placeholder, participants
    } = this.props;
    const clearable = false;
    return (
      <Select
        name={name}
        value={value}
        onChange={this.handleChange}
        clearable={clearable}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        options={this.getOptions(participants)}
      />
    );
  }
}

export default ParticipantSelect;

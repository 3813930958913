import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import ParticipantSelect from "./ParticipantSelect";
import Participant from "./Participant";
import {
  getParticipantLabels,
  updateParticipantLabel,
} from "../../../teacup-gg";

const propTypes = {
  bracket: PropTypes.string,
};

const defaultProps = {
  bracket: null,
};

class ParticipantEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: {
        label: "",
        value: "",
      },
      participant: null,
      labels: null,
    };
  }

  componentWillReceiveProps() {
    this.forceUpdate();
  }

  setLabels = (data) => {
    const participant = {};
    Object.keys(data).forEach((label) => {
      const labelValue = data[label].value;
      if (labelValue != null && labelValue !== "") {
        participant[label] = data[label].value;
      } else {
        participant[label] = data[label].default;
      }
    });
    this.setState({
      labels: data,
      participant,
    });
  };

  updateParticipant = (e) => {
    const { bracket } = this.props;
    this.setState({
      selected: {
        label: e.target.label,
        value: String(e.target.value),
      },
      labels: null,
      participant: null,
    });
    getParticipantLabels(bracket, e.target.value).then(
      (result) => {
        console.log(result);
        this.setLabels(result);
      },
      (e) => {
        console.log(e);
      }
    );
  };

  updateLabel = (id, field, value) => {
    const { participant } = this.state;
    if (participant != null) {
      const newParticipant = participant;
      newParticipant[field] = value;
      console.log(participant);
      this.setState(
        {
          participant: newParticipant,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  };

  update = (e) => {
    const { bracket } = this.props;
    const { selected, participant } = this.state;
    e.preventDefault();
    if (selected.value != null && selected.value !== "") {
      const participantID = selected.value;
      updateParticipantLabel(bracket, participantID, participant).then(
        () => {
          console.log("result returned.");
        },
        () => {
          console.log("error");
        }
      );
    }
  };

  showParticipant = () => {
    const { selected, participant, labels } = this.state;
    console.log(labels);
    if (selected != null && selected.value !== "") {
      if (participant != null && labels != null) {
        return (
          <div>
            <Participant
              participant={participant}
              labels={labels}
              editor={true}
              id={participant.value}
              onUpdate={this.updateLabel}
            />
            <Button
              className="float-right"
              color="primary"
              type="submit"
              onClick={(e) => this.update(e)}
            >
              Update
            </Button>
          </div>
        );
      }
      return <p>Loading participant...</p>;
    }
    return <p>Select a participant.</p>;
  };

  render() {
    const { bracket, user, participants } = this.props;
    if (bracket != null) {
      return (
        <div>
          <form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">Participant</span>
              <ParticipantSelect
                bracket={bracket}
                user={user}
                name="selectedParticipant"
                value={this.state.selected}
                placeholder="Participant Name"
                participants={participants}
                onChange={this.updateParticipant}
              />
            </div>
          </form>
          {this.showParticipant()}
        </div>
      );
    }
    return <p>Waiting on bracket.</p>;
  }
}

ParticipantEditor.propTypes = propTypes;
ParticipantEditor.defaultProps = defaultProps;

export default ParticipantEditor;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SelectField from "../../../shared/components/form/Select";
import Participant from "./Participant";
import { Col, Row } from "reactstrap";

class Entrant extends PureComponent {
  componentWillReceiveProps() {
    this.forceUpdate();
  }

  onChange = (e) => {
    const { entrant, onUpdate } = this.props;
    onUpdate(entrant.id, e.target.name, e.target.value);
  };

  updateParticipantLabel = (participant_id, label, data) => {
    const { entrant, onUpdate } = this.props;
    entrant.participants.forEach((participant) => {
      if (participant.id === participant_id) {
        participant[label] = data;
      }
    });
    onUpdate(entrant.id, "participants", entrant.participants);
  };

  getLabel = (label) => {
    const { entrant } = this.props;
    if (label.type === "string") {
      return (
        <div className="form__form-group">
          <span className="form__form-group-label">{label.name}</span>
          <div className="form__form-group-field">
            <input
              name={label.name}
              type="text"
              value={entrant[label.name] ?? label.default}
              placeholder={label.name}
              onChange={(e) => this.onChange(e)}
            />
          </div>
        </div>
      );
    } else if (label.type === "int") {
      return (
        <div className="form__form-group">
          <span className="form__form-group-label">{label.name}</span>
          <div className="form__form-group-field">
            <input
              name={label.name}
              type="number"
              value={entrant[label.name] ?? label.default}
              onChange={(e) => this.onChange(e)}
            />
          </div>
        </div>
      );
    } else if (label.type === "option_select") {
      const options = [];
      const selectedItem = {
        label: entrant[label.name] ?? label.default,
        value: entrant[label.name] ?? label.default,
      };
      label.options.forEach((option) => {
        options.push({
          label: option,
          value: option,
        });
      });
      return (
        <div className="form__form-group">
          <span className="form__form-group-label">{label.name}</span>
          <SelectField
            options={options}
            value={selectedItem}
            name={label.name}
            placeholder={label.name}
            onChange={this.onChange}
          />
        </div>
      );
    }
    return <br />;
  };

  getParticipants = (participants, labels, singleParticipant = false) => {
    return participants
      ? participants.map((participant) => {
          return (
            <Participant
              participant={participant}
              labels={labels}
              singleParticipant={singleParticipant}
              onUpdate={this.updateParticipantLabel}
            />
          );
        })
      : [];
  };

  getDefaultEntrantLabels = (singleParticipant) => {
    let labels = {
      score: {
        type: "int",
        name: "score",
        default: 0,
      },
    };
    if (!singleParticipant) {
      labels["team_name"] = {
        type: "string",
        name: "team_name",
        default: "",
      };
    }
    return labels;
  };

  showLabels = (singleParticipant = false) => {
    const { entrant } = this.props;
    const labelList = [];
    const labels = this.getDefaultEntrantLabels(singleParticipant);
    if (Object.keys(labels).length > 0) {
      Object.values(labels).forEach((label) => {
        labelList.push(this.getLabel(label));
      });
      return labelList;
    }
    return <p>Add labels to this bracket to edit participant data.</p>;
  };

  render() {
    const { entrant, labels } = this.props;
    if (!entrant.participants || entrant.participants.length === 1) {
      const participant = entrant.participants[0];
      return (
        <div>
          <form className="form">
            <div className="form__form-group">
              <h3>{participant.player_name}</h3>
            </div>
            <div className="form__form-group">
              {this.getParticipants(entrant.participants, labels, true)}
              {this.showLabels(true)}
            </div>
          </form>
        </div>
      );
    }
    return (
      <div>
        <form className="form">
          <div className="form__form-group">
            <h3>{entrant.team_name}</h3>
          </div>
          {this.showLabels()}
          <div className="form__form-group">
            {this.getParticipants(entrant.participants, labels)}
          </div>
        </form>
      </div>
    );
  }
}

export default Entrant;

import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import {auth, getUserData, logout} from '../../firebase';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaded: false,
      user: null,
    };
  }

  componentDidMount(){
    this.init()
  }

  init = async () => {
    let credential = this.props.cookies.get('credential');
    if(!credential){
      await logout();
    }
    auth.onAuthStateChanged(this.updateUser);
  }

  updateUser = async (currentUser=null, credential=null) => {
    console.log(currentUser);
    currentUser = !currentUser ? auth.currentUser : currentUser;
    let userData = null;
    if(currentUser){
      userData = await getUserData(currentUser.uid);
    }
    credential = !credential ? this.props.cookies.get('credential') : credential;
    this.setState({
      user: userData ? {data: userData, credential} : null,
      loading: false,
      loaded: true,
    }, () => {console.log(this.state)});
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Fragment>
              {!loaded
              && (
              <div className={`load${loading ? '' : ' loaded'}`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
              )
              }
              <div>
                {loaded && <Router updateUser={this.updateUser} user={this.state.user} />}
              </div>
            </Fragment>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default withCookies(App);

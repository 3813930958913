import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import Async from 'react-select/async';
import {getCurrentUserEvents} from '../../../smash-gg'

const propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  handleSubmit: PropTypes.func,
};

const defaultProps = {
  user: null,
  handleSubmit: null,
};

class ChallongeImportForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      challongeURL: {
        value: '',
        label: '',
      },
      status: 'Enter the Smash.gg Event Name below.',
    };
  }

  loadBracket = (e) => {
    const { user, handleSubmit } = this.props;
    const { challongeURL } = this.state;
    e.preventDefault();
    if(challongeURL.value){
      handleSubmit(challongeURL.value);
    }
  }

  updateURL = (selectedValue) => {
    console.log(selectedValue)
    this.setState({
      challongeURL: {
        value: selectedValue.value,
        label: selectedValue.label,
      },
    });
  }

  challongeCodeToURL = (challongeCode) => {
    let parts = challongeCode.split("-")
    if (parts.length > 1) {
      return 'https://' + parts[0] + '.challonge.com/' + parts.slice(1).join('-')
    }
    else if (parts.length > 0) {
      return 'https://' + 'challonge.com/' +challongeCode
    }
    return null
  }

  challongeURLToCode = (challongeURL) => {
    const url = challongeURL.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
    let parts = url.split('/')
    if(parts.length > 1){
      let code = parts[1]
      let hostParts = parts[0].split('.')
      if(hostParts.length > 2){
        let host = hostParts[0]
        return host+'-'+code
      }
      return code
    }
    return null
  }

  loadOptions = (inputValue, callback) => {
    const { user } = this.props;
    getCurrentUserEvents(user.data.smash_gg_key)
      .then(
        (result) => {
            console.log(JSON.stringify(result));
            if(!result.nodes){
              this.setState({status: "Error fetching events. Please check your Smash.gg credentials."});
              return callback([]);
            }
            this.filterOptions(result.nodes, inputValue, callback);
          }
      ).catch((e) => {
        console.log(e);
      });
  }

  filterOptions = (data, inputValue, callback) => {
    const filteredOptions = [];
    data.forEach((tournament) => {
      tournament.events.forEach((event) => {
        console.log(event)
        const name = `${tournament.name}: ${event.name}`;
        if (name.toLowerCase().includes(inputValue.toLowerCase())) {
          filteredOptions.push({
            value: event.id,
            label: name,
          });
        }
      });
    });
    callback(filteredOptions);
  }

  render() {
    const { status } = this.state;
    return (
      <div>
        <p><b>{status}</b></p>
        <br />
        <form className="form">
          <div className="form__form-group">
            <label htmlFor="challongeURL" className="form__form-group-label">Smash.gg Bracket</label>
            <Async
              name="challongeURL"
              value={this.state.challongeURL}
              onChange={this.updateURL}
              loadOptions={this.loadOptions}
              clearable={true}
              className="react-select"
              placeholder="Smash.gg Event"
              classNamePrefix="react-select"
              defaultOptions={true}
            />
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button
              color="primary"
              type="submit"
              onClick={e => this.loadBracket(e)}
            >
              Load
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    );
  }
}

ChallongeImportForm.propTypes = propTypes;
ChallongeImportForm.defaultProps = defaultProps;

export default ChallongeImportForm;

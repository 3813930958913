import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class StreamQueueSelect extends PureComponent {

  handleChange = (selectedOption) => {
    const { name, onChange } = this.props;
    onChange({
      target: {
        name,
        value: selectedOption.value,
        label: selectedOption.label,
      },
    });
  };

  getOptions = (data) => {
    const options = [];
    for(const queue_name in data){
      options.push({
        value: queue_name,
        label: queue_name
      });
    }
    return options;
  }

  render() {
    const {
      value, name, placeholder, stream_queues
    } = this.props;
    const clearable = false;
    return (
      <Select
        name={name}
        value={value}
        onChange={this.handleChange}
        clearable={clearable}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        options={this.getOptions(stream_queues)}
      />
    );
  }
}

export default StreamQueueSelect;

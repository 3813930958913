import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { auth, registerWithEmail } from "../../../firebase";

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      formDisabled: false,
      status: '',
    };
  }

  register = (values) => {
    console.log(values);
    const { email, username, password, passwordConfirm } = values;
    this.setState({
      formDisabled: true,
    });
    if(password !== passwordConfirm){
      this.setState({
        formDisabled: false,
        status: 'Passwords do not match!'
      });
      return;
    }
    console.log('creating user...');
    registerWithEmail(username, email, password)
      .then(
        (data) => {
            console.log(data);
            if (data !== null) {
              this.props.onSuccess({
                data,
              });
            } else {
              this.setState({
                formDisabled: false,
                status: 'Could not create user!'
              });
            }
          },
        (error) => {
          console.log('error returned.');
          console.log(error);
          this.setState({
            formDisabled: false,
            status: 'An error occurred while logging in.'
          });
        },
      );
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  showStatus = () => {
    if (this.state.status && this.state.status !== '') {
      return (
        <div className="form__form-group">
          <p><b>{this.state.status}</b></p>
        </div>
      );
    }
    return null;
  }

  render() {
    const { handleSubmit } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit(this.register)}>
        {this.showStatus()}
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <EmailIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="text"
              placeholder="Email"
              disabled={this.state.formDisabled ? 'disabled' : ''}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Display Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="username"
              component="input"
              type="text"
              placeholder="Display Name"
              disabled={this.state.formDisabled ? 'disabled' : ''}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              disabled={this.state.formDisabled ? 'disabled' : ''}
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              disabled={this.state.formDisabled ? 'disabled' : ''}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Confirm Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="passwordConfirm"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              disabled={this.state.formDisabled ? 'disabled' : ''}
            />
          </div>
        </div>
        <Button className="account__btn" type="submit" disabled={this.state.formDisabled ? 'disabled' : ''} color="primary">Register</Button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'log_in_form',
})(LogInForm);
import React, { PureComponent } from "react";
import { Field, reduxForm, change } from "redux-form";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import PropTypes from "prop-types";
import Round from "./Round";
import Entrant from "./Entrant";
import ImportForm from "./ImportForm";
import StreamQueueSelect from "./StreamQueueSelect";
import {
  listenForMatchLabelUpdates,
  getOrCreateMatchLabels,
  setMatchLabels,
  getParticipantLabels,
} from "../../../teacup-gg";

const _ = require("lodash");

class Match extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      labelData: {},
    };
  }

  componentDidUpdate(prevProps) {
    let { currentMatch, labelData, stream_queue } = this.state;
    let { stream_queues, updateCurrentMatch } = this.props;
    console.log(stream_queues);
    let new_match = this.getCurrentMatch();
    let new_match_id = new_match ? new_match.id : null;
    if (!(stream_queue in stream_queues)) {
      stream_queue = Object.keys(stream_queues)[0] ?? null;
    }
    if (new_match_id !== currentMatch) {
      updateCurrentMatch(stream_queue, new_match_id);
      this.initLabelData(new_match);
    }
    this.setState({
      stream_queue,
      currentMatch: new_match_id,
    });
  }

  initLabelData = async (new_match) => {
    let { labelListener } = this.state;
    if (labelListener) {
      labelListener();
    }
    if (!new_match) {
      return;
    }
    const defaultLabelData = await this.getDefaultLabelData(new_match);
    const teacupMatchData = await getOrCreateMatchLabels(
      new_match.event.id,
      new_match.id,
      defaultLabelData
    );
    labelListener = listenForMatchLabelUpdates(
      new_match.event.id,
      new_match.id,
      this.handleLabelDataUpdate
    );
    this.setState({
      labelListener,
    });
  };

  getDefaultLabelData = async (currentMatch) => {
    const { event } = this.props;
    let labelData = {
      event_name: event.name,
      tournament_name: event.tournament.name,
    };
    if (currentMatch) {
      labelData = Object.assign(labelData, {
        round: currentMatch.fullRoundText,
        entrants: currentMatch.slots.map((slot) => {
          return {
            id: slot.entrant.id,
            team_name: slot.entrant.name,
            score: slot.standing.stats.score.value ?? 0,
            participants: slot.entrant.participants.map((participant) => {
              return {
                player_name: participant.player.gamerTag,
                id: participant.player.id,
              };
            }),
          };
        }),
      });
      labelData.entrants = await Promise.all(
        labelData.entrants.map((entrant) =>
          this.addParticipantLabelsToEntrant(event.id, entrant)
        )
      );
    }
    return labelData;
  };

  addParticipantLabelsToParticipant = async (event_id, participant) => {
    let participant_labels = await getParticipantLabels(
      event_id,
      participant.id
    );
    let participant_data = {};
    for (let label in participant_labels) {
      let data = participant_labels[label];
      participant_data[label] = data.value ? data.value : data.default;
    }
    return Object.assign(participant, participant_data);
  };

  addParticipantLabelsToEntrant = async (event_id, entrant) => {
    entrant.participants = await Promise.all(
      entrant.participants.map((participant) =>
        this.addParticipantLabelsToParticipant(event_id, participant)
      )
    );
    return entrant;
  };

  getCurrentMatch = () => {
    const { stream_queue } = this.state;
    const { stream_queues } = this.props;
    if (stream_queue && stream_queue in stream_queues) {
      return stream_queues[stream_queue][0] ?? null;
    }
    return null;
  };

  updateLabelData = (data) => {
    this.setState(
      (prevState) => {
        let newData = {};
        newData[data.target.name] = data.target.value;
        let newState = Object.assign(prevState.labelData, newData);
        return newState;
      },
      () => {
        console.log(this.state);
        this.forceUpdate();
      }
    );
  };

  updateEntrantLabel = (id, label, data) => {
    const { labelData } = this.state;
    labelData.entrants.forEach((entrant) => {
      if (entrant.id === id) {
        entrant[label] = data;
      }
    });
    this.setState(
      {
        labelData,
      },
      () => {
        console.log(this.state.labelData);
        this.forceUpdate();
      }
    );
  };

  updateStreamQueue = (data) => {
    this.setState((prevState) => {
      return { stream_queue: data.target.value };
    });
  };

  flip = (e) => {
    const { labelData } = this.state;
    let shuffle = labelData.entrants;
    while (_.isEqual(labelData.entrants, shuffle)) {
      shuffle = _.shuffle(labelData.entrants);
    }
    labelData.entrants = shuffle;
    this.setState(labelData, () => {
      this.handleUpdate();
    });
  };

  finish = (e) => {
    const { bracket, user } = this.props;
    const currentMatch = this.getCurrentMatch();
    e.preventDefault();
    currentMatch.id = bracket;
    const apiURL = `${process.env.REACT_APP_TEACUP_API}/match/complete`;
    currentMatch.id = bracket;
    currentMatch.user = user.user;
    currentMatch.key = user.key;
    fetch(apiURL, {
      method: "put",
      body: JSON.stringify(currentMatch),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
      },
      () => {
        console.log("error");
      }
    );
  };

  getEntrants = (entrants, labels) => {
    return entrants
      ? entrants.map((entrant) => {
          return (
            <Col>
              <Entrant
                entrant={entrant}
                labels={labels}
                onUpdate={this.updateEntrantLabel}
              />
            </Col>
          );
        })
      : [];
  };

  handleLabelDataUpdate = async (newLabelData) => {
    this.setState(
      {
        labelData: newLabelData,
      },
      () => {
        this.updateReduxForm();
      }
    );
  };

  updateReduxForm = () => {
    const { labelData } = this.state;
    this.props.dispatch(change("match_form", "round", labelData.round));
  };

  handleUpdate = async (values) => {
    const { labelData } = this.state;
    const currentMatch = this.getCurrentMatch();
    await setMatchLabels(currentMatch.event.id, currentMatch.id, labelData);
  };

  render() {
    const { bracket, user, labels, stream_queues, handleSubmit } = this.props;
    const { stream_queue, labelData } = this.state;
    const currentMatch = this.getCurrentMatch();
    console.log(stream_queue);
    if (bracket != null) {
      return (
        <form className="form" onSubmit={handleSubmit(this.handleUpdate)}>
          <div className="form__form-group">
            <span className="form__form-group-label">Stream Queue</span>
            <StreamQueueSelect
              value={
                stream_queue
                  ? { label: stream_queue, value: stream_queue }
                  : undefined
              }
              bracket={bracket}
              user={user}
              stream_queues={stream_queues}
              name="stream_queue"
              placeholder="Select Stream Queue"
              onChange={this.updateStreamQueue}
            />
          </div>
          {currentMatch ? (
            <div className="form__form-group">
              <div className="form__form-group">
                <span className="form__form-group-label">Round Name</span>
                <div className="form__form-group-field">
                  <Field
                    onChange={this.updateLabelData}
                    name="round"
                    component="input"
                    type="text"
                    placeholder="Round Name"
                    disabled={this.state.formDisabled ? "disabled" : ""}
                  />
                </div>
              </div>
              <Row>{this.getEntrants(labelData.entrants, labels)}</Row>
              <div className="float-right">
                {false && (
                  <Button
                    type="submit"
                    color="danger"
                    onClick={(e) => this.finish(e)}
                  >
                    Finish Set
                  </Button>
                )}
                <Button
                  type="submit"
                  color="secondary"
                  onClick={(e) => this.flip(e)}
                >
                  Flip Players
                </Button>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </div>
            </div>
          ) : (
            <p>Waiting on match.</p>
          )}
        </form>
      );
    }
    return <ImportForm user={user} handleSubmit={this.props.loadBracket} />;
  }
}

export default reduxForm({ form: "match_form" })(Match);

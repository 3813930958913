// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateEmail,
  signOut,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import {
  setDoc,
  getDoc,
  doc,
  collection,
  getFirestore,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const devConfig = {
  apiKey: "AIzaSyAYXzIhzaKLXfCgxikhX51etUsVbNYAfeg",
  authDomain: "teacup-gg-dev.firebaseapp.com",
  projectId: "teacup-gg-dev",
  storageBucket: "teacup-gg-dev.appspot.com",
  messagingSenderId: "36528960893",
  appId: "1:36528960893:web:d1fa71eef2fcce07f00814",
  measurementId: "G-NFSH9S1GSX",
};

const prodConfig = {
  apiKey: "AIzaSyDB__I8D3Cl_3vjIA-diNaBWi2KS-jECIc",
  authDomain: "teacup-gg.firebaseapp.com",
  projectId: "teacup-gg",
  storageBucket: "teacup-gg.appspot.com",
  messagingSenderId: "609707540838",
  appId: "1:609707540838:web:1aefe89fcb956c0e4b48a7",
  measurementId: "G-K8M97QB45M",
};

let firebaseConfig =
  window.location.hostname === "teacup.gg" ? prodConfig : devConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const db = getFirestore();
const storage = getStorage();

const registerWithEmail = async (username, email, password) => {
  const data = await createUserWithEmailAndPassword(auth, email, password);
  const uid = data.user.uid;
  const userRef = doc(db, "users", uid);
  await setDoc(userRef, {
    uid,
    email,
    username,
  });
  const userDoc = await getDoc(userRef);
  return userDoc.data();
};

const uploadFile = async (path, file) => {
  const storageRef = ref(storage, path);
  let snapshot = await uploadBytes(storageRef, file);
  return snapshot;
};

const getFileURL = async (path) => {
  const storageRef = ref(storage, path);
  return await getDownloadURL(storageRef);
}

const reauthUser = async (email, password) => {
  console.log(email + " " + password);
  const credential = EmailAuthProvider.credential(email, password);
  try {
    await reauthenticateWithCredential(auth.currentUser, credential);
    return { email: credential._email, password: credential._password };
  } catch (e) {
    console.log(e);
    let userData = await signInWithEmail(email, password);
    return userData.credential;
  }
};

const updateUserPassword = async (credential, uid, password) => {
  await reauthUser(credential.email, credential.password);
  await updatePassword(auth.currentUser, password);
};

const updateUserEmail = async (credential, uid, new_email) => {
  await reauthUser(credential.email, credential.password);
  await updateEmail(auth.currentUser, new_email);
  await updateUserData(uid, { email: new_email });
};

const updateUserData = async (uid, data) => {
  const userRef = doc(db, "users", uid);
  await updateDoc(userRef, data);
};

const getUserData = async (uid, createIfNull = false, email = null) => {
  const userRef = doc(db, "users", uid);
  let userDoc = await getDoc(userRef);
  if (!userDoc.exists() && createIfNull) {
    await setDoc(userRef, {
      uid,
      email,
      username: email,
    });
    userDoc = await getDoc(userRef);
  }
  return userDoc.data();
};

const listenForUserDataUpdates = (uid, callback) => {
  uid = uid.toString();
  const userRef = doc(db, "users", uid);
  return onSnapshot(userRef, (doc) => {
    callback(doc.data());
  });
};

const logout = async () => {
  await signOut(auth);
};

const signInWithEmail = async (email, password) => {
  const credential = EmailAuthProvider.credential(email, password);
  const data = await signInWithEmailAndPassword(auth, email, password);
  const uid = data.user.uid;
  const user = await getUserData(uid, true, email);
  return {
    user,
    credential: { email: credential._email, password: credential._password },
  };
};

const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export {
  auth,
  db,
  getFirestore,
  getUserData,
  updateUserEmail,
  updateUserData,
  signInWithEmail,
  registerWithEmail,
  sendPasswordResetEmail,
  logout,
  reauthUser,
  updateUserPassword,
  uploadFile,
  getFileURL,
  listenForUserDataUpdates
};

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SelectField from "../../../shared/components/form/Select";
import { Col, Row } from "reactstrap";

class Participant extends PureComponent {
  componentWillReceiveProps() {
    this.forceUpdate();
  }

  onChange = (e) => {
    const { participant, onUpdate } = this.props;
    onUpdate(participant.id, e.target.name, e.target.value);
  };

  getLabel = (label) => {
    const { participant } = this.props;
    if (label.type === "string") {
      return (
        <div className="form__form-group">
          <span className="form__form-group-label">{label.name}</span>
          <div className="form__form-group-field">
            <input
              name={label.name}
              type="text"
              value={participant[label.name] ?? label.default}
              placeholder={label.name}
              onChange={(e) => this.onChange(e)}
            />
          </div>
        </div>
      );
    } else if (label.type === "int") {
      return (
        <div className="form__form-group">
          <span className="form__form-group-label">{label.name}</span>
          <div className="form__form-group-field">
            <input
              name={label.name}
              type="number"
              value={participant[label.name] ?? label.default}
              onChange={(e) => this.onChange(e)}
            />
          </div>
        </div>
      );
    } else if (label.type === "option_select") {
      const options = [];
      const selectedItem = {
        label: participant[label.name] ?? label.default,
        value: participant[label.name] ?? label.default,
      };
      label.options.forEach((option) => {
        options.push({
          label: option,
          value: option,
        });
      });
      return (
        <div className="form__form-group">
          <span className="form__form-group-label">{label.name}</span>
          <SelectField
            options={options}
            value={selectedItem}
            name={label.name}
            placeholder={label.name}
            onChange={this.onChange}
          />
        </div>
      );
    }
    return <br />;
  };

  getParticipants = (participants) => {
    return participants
      ? participants.map((participant) => {
          return (
            <Row>
              <Participant participant={participant} />
            </Row>
          );
        })
      : [];
  };

  getDefaultParticipantLabels = (labels, editor = false) => {
    let defaultLabels = {};
    if (!editor) {
      defaultLabels["player_name"] = {
        type: "string",
        name: "player_name",
        default: "",
      };
    }
    return Object.assign(defaultLabels, labels);
  };

  showLabels = (labels, editor = false) => {
    const { participant } = this.props;
    const labelList = [];
    const defaultLabels = this.getDefaultParticipantLabels(labels, editor);
    if (Object.keys(defaultLabels).length > 0) {
      Object.values(defaultLabels).forEach((label) => {
        labelList.push(this.getLabel(label));
      });
      return labelList;
    }
    return <p>Add labels to this bracket to edit participant data.</p>;
  };

  render() {
    const { participant, singleParticipant, labels, editor } = this.props;
    return (
      <div>
        {!singleParticipant && <h4><b>{participant.player_name}</b></h4>}
        {this.showLabels(labels, editor)}
      </div>
    );
  }
}

export default Participant;

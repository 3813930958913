import React, { Component } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { listenForUserDataUpdates } from "../../firebase";
import {
  listenForTournamentUpdates,
  listenForMatchLabelUpdates,
  listenForSourceUpdates,
  listenForEventLabels,
  getAsset,
} from "../../teacup-gg";
import { getFileURL } from "../../firebase";

class StreamSource extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    const particpant_index = parseInt(query.get("participant"));
    const entrant_index = parseInt(query.get("entrant"));
    this.state = {
      user_id: props.match.params.user,
      stream_code: props.match.params.stream_code,
      source_name: props.match.params.source,
      particpant_index: isNaN(particpant_index) ? 0 : particpant_index,
      entrant_index: isNaN(entrant_index) ? 0 : entrant_index,
      user: null,
      event: null,
      event_id: null,
      match_id: null,
      event_listener: null,
      match_listener: null,
      match: null,
      source_listener: null,
      labels_listener: null,
      labels: null,
      assets: null,
    };
  }

  componentDidMount() {
    this.init();
  }

  updateMatch = async (match) => {
    await this.setState({ match });
  };

  updateSource = async (source) => {
    const { event_id } = this.state;
    let asset_listener = this.state.asset_listener;
    if (asset_listener) {
      asset_listener();
    }
    let assets = {};
    if (source.type === "image" && source.mappings) {
      let map_values = Object.values(source.mappings);
      let map_labels = Object.keys(source.mappings);
      for (let i in map_values) {
        let asset_name = map_values[i];
        let asset = await getAsset(event_id, asset_name);
        let url = await getFileURL(asset.path);
        assets[map_labels[i]] = url;
      }
    }
    console.log(assets);
    await this.setState({ source, assets });
  };

  updateLabels = async (labels) => {
    labels = Object.assign(labels, {
      event_name: {
        name: "event_name",
        type: "string",
        location: "match",
        default: "",
      },
      tournament_name: {
        name: "tournament_name",
        type: "string",
        location: "match",
        default: "",
      },
      round: {
        name: "round",
        type: "string",
        location: "match",
        default: "",
      },
      team_name: {
        type: "string",
        name: "team_name",
        location: "entrant",
        default: "",
      },
      score: {
        type: "int",
        name: "score",
        location: "entrant",
        default: 0,
      },
      player_name: {
        type: "string",
        name: "player_name",
        location: "participant",
        default: "",
      },
    });
    console.log(labels);
    await this.setState({ labels });
  };

  resetEvent = async () => {
    const {
      event_listener,
      match_listener,
      source_listener,
      labels_listener,
      asset_listener,
    } = this.state;
    if (event_listener) {
      event_listener();
    }
    if (match_listener) {
      match_listener();
    }
    if (source_listener) {
      source_listener();
    }
    if (labels_listener) {
      labels_listener();
    }
    if (asset_listener) {
      asset_listener();
    }
    await this.setState({
      event: null,
      match: null,
      event_id: null,
      match_id: null,
      event_listener: null,
      match_listener: null,
      source_listener: null,
      labels_listener: null,
      assets: null,
    });
  };

  updateEvent = async (event) => {
    const { stream_code, event_id, stream_queue } = this.state;
    let match_listener = this.state.match_listener;
    console.log(event);
    let match_id = event ? event.currentMatch[stream_queue] ?? null : null;
    await this.setState({ event, match_id });
    if (match_listener) {
      match_listener();
    }
    match_listener = match_id
      ? listenForMatchLabelUpdates(event_id, match_id, this.updateMatch)
      : null;
    this.setState({
      match_listener,
    });
  };

  updateUser = async (user) => {
    const { stream_code, source_name } = this.state;
    let event_listener = this.state.event_listener;
    let source_listener = this.state.source_listener;
    let labels_listener = this.state.labels_listener;
    let event_id =
      user.streams && user.streams[stream_code]
        ? user.streams[stream_code].id
        : null;
    let stream_queue = user.streams && user.streams[stream_code]
        ? user.streams[stream_code].stream_queue
        : null;
    await this.setState({
      user,
      event_id,
      stream_queue,
    });
    if (event_listener) {
      event_listener();
    }
    if (source_listener) {
      source_listener();
    }
    if (labels_listener) {
      labels_listener();
    }
    if (event_id) {
      console.log(this.state)
      event_listener = listenForTournamentUpdates(event_id, this.updateEvent);
      source_listener = listenForSourceUpdates(
        event_id,
        source_name,
        this.updateSource
      );
      labels_listener = listenForEventLabels(event_id, this.updateLabels);
      await this.setState({
        event_listener,
        source_listener,
        labels_listener,
      });
    } else {
      await this.resetEvent();
    }
  };

  init = async () => {
    const { user_id } = this.state;
    let userListener = listenForUserDataUpdates(user_id, this.updateUser);
  };

  render() {
    const { source, match, labels, entrant_index, particpant_index, assets } =
      this.state;
    if (!source || !match || !labels) {
      return <div></div>;
    }
    const location = labels[source.label].location ?? "participant";
    let value = labels[source.label].default ?? "";
      if (location === "participant") {
        console.log(particpant_index);
        value =
          match.entrants[entrant_index].participants[particpant_index][
            source.label
          ] ?? value;
      } else if (location === "entrant") {
        value = match.entrants[entrant_index][source.label] ?? value;
      } else {
        value = match[source.label] ?? value;
      }
    if (source.type === "string") {
      return <p className={"source-text"}>{value}</p>;
    } else if (assets && source.type === "image") {
      if(value in assets){
        return <img src={assets[value]}/>;
      }
    }
    return <div></div>;
  }
}

export default withRouter(StreamSource);

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import Register from '../Register/index';
import Landing from '../Landing/index';
import Bracket from '../Bracket/index';
import Account from '../Account/index';
import StreamSource from '../StreamSource/index';

const Router = (props) => (
  <Switch>
    <Route exact path="/stream/:user/:stream_code/:source" component={StreamSource} />
    <MainWrapper>
      <main>
          <div>
          <Layout user={props.user} updateUser={props.updateUser} />
            <div className="container__wrap">
              <Route exact path="/" component={Landing} />
              <Route exact path="/home" component={Landing} />
              <Route exact path="/download" component={Landing} />
              <Route exact path="/downloads" component={Landing} />
              <Route exact path="/login" render={(p) => <LogIn {...p} updateUser={props.updateUser} user={props.user} />} />
              <Route exact path="/register" render={(p) => <Register {...p} updateUser={props.updateUser} user={props.user} />} />
              <Route exact path="/account" user={props.user} render={(p) => <Account {...p} updateUser={props.updateUser} user={props.user} />} />
              <Route path="/bracket/:id?" user={props.user} render={(p) => <Bracket {...p} user={props.user} />} />
            </div>
          </div>
      </main>
    </MainWrapper>
  </Switch>
);

export default Router;

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { withCookies, Cookies } from 'react-cookie';
import AccountForm from './components/AccountForm';

class Account extends Component {
  constructor(props) {
    super(props);
    if(!this.props.user){
      this.props.history.push({ pathname: '/' });
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(user) {
    if(this.props.cookies.get('credential')){
      this.props.cookies.set('credential', user.credential, {expires: new Date(Date.now() + 12096e5)});
    }
    this.props.updateUser(null, user.credential);
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col md="auto">
              <div className="account__wrapper">
                <div className="account__card">
                  <div className="account__head">
                    <h3 className="account__title">Update Account</h3>
                  </div>
                  <AccountForm onSuccess={this.handleSubmit} user={this.props.user}/>
                </div>
              </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withCookies(Account));

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles

import React, { PureComponent } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import LockOutlineIcon from 'mdi-react/LockOutlineIcon';
import LockResetIcon from 'mdi-react/LockResetIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { auth, updateUserEmail, updateUserData, reauthUser, updateUserPassword, getUserData } from "../../../firebase";

class AccountForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      formDisabled: false,
      status: '',
    };
  }

  componentDidMount(){
    if(this.props.user){
      this.props.dispatch(change('account_form', 'email', this.props.user.data.email));
      this.props.dispatch(change('account_form', 'username', this.props.user.data.username));
      this.props.dispatch(change('account_form', 'smash_gg_key', this.props.user.data.smash_gg_key));
    }
  }

  update = async (values) => {
    console.log(values);
    const { email, username, password, passwordConfirm, smash_gg_key } = values;
    const { user } = this.props;
    await this.setState({
      formDisabled: true,
    });
    if((password || passwordConfirm) && password !== passwordConfirm){
      return await this.setState({
        formDisabled: false,
        status: 'Passwords need to match.'
      });
    }
    if(!email || email === ""){
      return await this.setState({
        formDisabled: false,
        status: 'Email is required!'
      });
    }
    if(!username || username === ""){
      return await this.setState({
        formDisabled: false,
        status: 'Display Name is required!'
      });
    }
    if(username !== user.data.username){
      await updateUserData(user.data.uid, {
        username,
      });
    }
    if(smash_gg_key !== user.data.smash_gg_key){
      await updateUserData(user.data.uid, {
        smash_gg_key,
      });
    }
    if(email !== user.data.email) {
      try{
        await updateUserEmail(user.credential, user.data.uid, email);
      }
      catch(e){
        this.props.dispatch(change('account_form', 'email', user.data.email));
        return await this.setState({
          formDisabled: false,
          status: 'Error updating email!'
        });
      }
      user.credential = await reauthUser(email, user.credential.password);
    }
    if(password){
      try{
        await updateUserPassword(user.credential, user.data.uid, password);
      }
      catch(e){
        return await this.setState({
          formDisabled: false,
          status: 'Error updating password!'
        });
      }
      user.credential = await reauthUser(user.credential.email, password);
    }
    user.data = await getUserData(user.data.uid, false, user.credential.email);
    this.props.onSuccess(user);
    this.setState({
      formDisabled: false,
      status: 'Successfully updated Account.'
    });
    this.props.dispatch(change('account_form', 'password', undefined));
    this.props.dispatch(change('account_form', 'passwordConfirm', undefined));
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  showStatus = () => {
    if (this.state.status && this.state.status !== '') {
      return (
        <div className="form__form-group">
          <p><b>{this.state.status}</b></p>
        </div>
      );
    }
    return null;
  }

  render() {
    const { handleSubmit, user } = this.props;
    const { showPassword } = this.state;
    return (
      <form className="form" onSubmit={handleSubmit(this.update)}>
        {this.showStatus()}
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <EmailIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="text"
              placeholder="Email"
              disabled={this.state.formDisabled ? 'disabled' : ''}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Display Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="username"
              component="input"
              type="text"
              placeholder="Display Name"
              disabled={this.state.formDisabled ? 'disabled' : ''}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Update Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LockOutlineIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              disabled={this.state.formDisabled ? 'disabled' : ''}
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              disabled={this.state.formDisabled ? 'disabled' : ''}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Confirm Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <LockResetIcon />
            </div>
            <Field
              name="passwordConfirm"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              disabled={this.state.formDisabled ? 'disabled' : ''}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Smash.gg API Key</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="smash_gg_key"
              component="input"
              type="text"
              placeholder="Smash.gg API Key"
              disabled={this.state.formDisabled ? 'disabled' : ''}
            />
          </div>
        </div>
        <Button className="account__btn" type="submit" disabled={this.state.formDisabled ? 'disabled' : ''} color="primary">Update</Button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'account_form',
})(AccountForm);
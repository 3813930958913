import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { withCookies, Cookies } from 'react-cookie';
import LogInForm from './components/LogInForm';

class LogIn extends Component {
  constructor(props) {
    super(props);
    if(this.props.user){
      this.props.history.push({ pathname: '/' });
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    console.log(data.data.credential)
    if(data.rememberMe){
       this.props.cookies.set('credential', data.data.credential, {expires: new Date(Date.now() + 12096e5)});
    }
    this.props.updateUser(null, data.data.credential);
    const locationState = this.props.history.location.state
    if(locationState && locationState.returnAddress){
      console.log(locationState.returnAddress);
      this.props.history.push({ pathname: locationState.returnAddress });
    }
    else{
      this.props.history.push({ pathname: '/' });
    }
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col md="auto">
              <div className="account__wrapper">
                <div className="account__card">
                  <div className="account__head">
                    <h3 className="account__title">Welcome to
                      <span className="account__logo"> teacup
                        <span className="account__logo-accent">.gg</span>
                      </span>
                    </h3>
                    <h4 className="account__subhead subhead">The Stream Assistant for Challonge & Smash.gg</h4>
                  </div>
                  <LogInForm onSuccess={this.handleSubmit}/>
                </div>
              </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withCookies(LogIn));

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles

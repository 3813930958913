import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import Label from "./Label";
import { updateAsset, deleteAsset } from "../../../teacup-gg";

class AssetUploader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: {
        label: "",
        value: "",
      },
      selectedFile: null,
      label: null,
    };
  }

  componentWillReceiveProps() {
    this.forceUpdate();
  }

  updateSelectedLabel = (selectedOption) => {
    const { assets, addAsset } = this.props;
    if (selectedOption.value in assets) {
      const label = assets[selectedOption.value];
      this.setState(
        {
          selected: selectedOption,
          selectedFile: null,
          label,
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      const label = {
        name: selectedOption.value,
      };
      this.setState({
        selected: selectedOption,
        label,
      });
      addAsset(label);
    }
  };

  update = async (e) => {
    const { label } = this.state;
    const { bracket } = this.props;
    e.preventDefault();
    if (label != null) {
      await updateAsset(bracket, label, this.state[label.name]);
    }
  };

  delete = async (e) => {
    const { label } = this.state;
    const { bracket } = this.props;
    e.preventDefault();
    if (label != null) {
      await deleteAsset(bracket, label);
      this.setState({
        selected: {
          label: "",
          value: "",
        },
        label: null,
      });
    }
  };

  showAsset = () => {
    const { selected, label, selectedFile } = this.state;
    if (selected != null && selected.value !== "") {
      if (label != null) {
        return (
          <div>
            <form className="form">
              <div className="form__form-group">
                <span className="form__form-group-label">File Upload</span>
                <div className="form__form-group-field">
                  <input
                    name={label.name}
                    type="file"
                    onChange={(e) => {
                      this.setState(
                        {
                          [e.target.name]: e.target.files[0],
                        },
                        () => {
                          console.log(this.state);
                        }
                      );
                    }}
                  />
                </div>
              </div>{" "}
            </form>
            <div className="float-right">
              <Button
                color="secondary"
                type="submit"
                onClick={(e) => this.delete(e)}
              >
                Delete
              </Button>
              <Button
                color="primary"
                type="submit"
                onClick={(e) => this.update(e)}
              >
                Update
              </Button>
            </div>
          </div>
        );
      }
      return <p>Loading label...</p>;
    }
    return <p>Select a label.</p>;
  };

  render() {
    const { bracket, assets } = this.props;
    if (bracket != null && assets) {
      const options = [];
      Object.keys(assets).forEach((option) => {
        options.push({
          label: option,
          value: option,
        });
      });
      return (
        <div>
          <form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">Asset</span>
              <CreatableSelect
                name="selectedLabel"
                value={this.state.selected}
                placeholder="Label Name"
                onChange={this.updateSelectedLabel}
                options={options}
              />
            </div>
          </form>
          {this.showAsset()}
        </div>
      );
    }
    return <p>Waiting on bracket.</p>;
  }
}

export default AssetUploader;

import { db, uploadFile, getUserData, updateUserData } from "./firebase";
import {
  setDoc,
  deleteDoc,
  getDocs,
  getDoc,
  doc,
  collection,
  updateDoc,
  onSnapshot,
  query
} from "firebase/firestore";

const getParticipantLabels = async (id, participant_id) => {
  id = id.toString();
  participant_id = participant_id.toString();
  let tournamentRef = doc(db, "tournaments", id);
  let participantRef = doc(
    collection(tournamentRef, "participants"),
    participant_id
  );
  let participantDoc = await getDoc(participantRef);
  let tournament_labels = await getLabelsByEventID(id);
  let participantData = participantDoc.exists() ? participantDoc.data() : null;
  for (const label_name in tournament_labels) {
    const tournament_label = tournament_labels[label_name];
    tournament_label.value = "";
    if (participantData) {
      if (label_name in participantData) {
        tournament_label.value = String(participantData[label_name]);
      }
    }
  }
  return tournament_labels;
};

const updateParticipantLabel = async (id, participant_id, labels) => {
  id = id.toString();
  participant_id = participant_id.toString();
  let tournamentRef = doc(db, "tournaments", id);
  let participantRef = doc(
    collection(tournamentRef, "participants"),
    participant_id
  );
  await setDoc(participantRef, labels);
};

const updateLabel = async (id, label) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  let labelRef = doc(
    collection(tournamentRef, "participant_labels"),
    label.name
  );
  setDoc(labelRef, {
    type: label.type,
    default: label.default,
  });
  if (label.type == "option_select") {
    let optionsRef = collection(labelRef, "options");
    let options = await getDocs(optionsRef);
    options.forEach((option) => {
      if (!label.options.includes(option.id)) {
        deleteDoc(option.ref);
      }
    });
    label.options.forEach((option) => {
      setDoc(doc(optionsRef, option), {
        default: option == label.default,
      });
    });
  }
};

const deleteLabel = async (id, label) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  let labelRef = doc(
    collection(tournamentRef, "participant_labels"),
    label.name
  );
  deleteDoc(labelRef);
};

const getLabelsByEventID = async (id) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  const labels = await getDocs(collection(tournamentRef, "participant_labels"));
  var labelData = [];
  var labelObj = {};
  labels.forEach((label) => {
    labelData.push(getLabelData(label));
  });
  let tournament_labels = await Promise.all(labelData);
  tournament_labels.forEach((tournament_label) => {
    labelObj[tournament_label.name] = tournament_label;
  });
  return labelObj;
};

const listenForEventLabels = (id, callback) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  const labelsRef = collection(tournamentRef, "participant_labels");
  return onSnapshot(query(labelsRef), async () => {
    let labels = await getLabelsByEventID(id);
    callback(labels);
  });
}

const getAssetsByEventID = async (id) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  const assets = await getDocs(collection(tournamentRef, "assets"));
  var assetObj = {};
  assets.forEach((asset) => {
    assetObj[asset.data().name] = asset.data();
  });
  console.log(assets);
  return assetObj;
};

const updateAsset = async (id, asset, file) => {
  id = id.toString();
  let uploadedFile = await uploadFile(`${id}/asset/${file.name}`, file);
  asset.path = uploadedFile.metadata.fullPath;
  const tournamentRef = doc(db, "tournaments", id);
  let assetRef = doc(collection(tournamentRef, "assets"), asset.name);
  await setDoc(assetRef, asset);
};

const setAsset = async (id, asset) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  let assetRef = doc(collection(tournamentRef, "assets"), asset.name);
  await setDoc(assetRef, asset);
};

const getAsset = async (id, asset_name) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  let assetRef = doc(collection(tournamentRef, "assets"), asset_name);
  let asset = await getDoc(assetRef);
  return asset.exists() ? asset.data() : {};
};

const deleteAsset = async (id, asset) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  let assetRef = doc(collection(tournamentRef, "assets"), asset.name);
  await deleteDoc(assetRef);
};

const updateTournament = async (id, event) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  await setDoc(tournamentRef, event);
};

const isTournamentStreaming = async (id, user_id, stream_queue) => {
  id = id.toString();
  user_id = user_id.toString();
  let event = await getTournamentByID(id);
  let user = await getUserData(user_id);
  return (
    user &&
    event &&
    event.stream_code &&
    user.streams &&
    Object.keys(user.streams).includes(event.stream_code) &&
    user.streams[event.stream_code]['id'] === id
  );
};

const toggleStreamingTournament = async (id, user_id, streaming, stream_queue) => {
  id = id.toString();
  user_id = user_id.toString();
  let event = await getTournamentByID(id);
  let user = await getUserData(user_id);
  if (!user || !event || !event.stream_code || event.stream_code === "") {
    return false;
  }
  user.streams = user.streams ? user.streams : {};
  user.streams[event.stream_code] = streaming ? {id, stream_queue} : null;
  await updateUserData(user_id, user);
  return streaming;
};

const getTournamentByID = async (id) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  let event = await getDoc(tournamentRef);
  return event.exists() ? event.data() : {};
};

const listenForTournamentUpdates = (id, callback) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  return onSnapshot(tournamentRef, (doc) => {
    callback(doc.data());
  });
}

const cloneTournamentByID = async (id, target) => {
  id = id.toString();
  target = target.toString();
  let tournament = await getTournamentByID(id);
  tournament.currentMatch = {};
  let assets = await getAssetsByEventID(id);
  let participant_labels = await getLabelsByEventID(id);
  let sources = await getSourcesByEventID(id);
  await updateTournament(target, tournament);
  Object.keys(assets).forEach((asset_name) => {
    setAsset(target, assets[asset_name]);
  });
  Object.keys(participant_labels).forEach((label_name) => {
    updateLabel(target, participant_labels[label_name]);
  });
  Object.keys(sources).forEach((source_name) => {
    updateSource(target, sources[source_name]);
  });
}

const listenForSourceUpdates = (id, source_name, callback) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  let sourceRef = doc(collection(tournamentRef, "sources"), source_name);
  return onSnapshot(sourceRef, (doc) => {
    callback(doc.data());
  });
}

const listenForAssetUpdates = (id, asset_name, callback) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  let assetRef = doc(collection(tournamentRef, "assets"), asset_name);
  return onSnapshot(assetRef, (doc) => {
    callback(doc.data());
  });
}

const getSourcesByEventID = async (id) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  const sources = await getDocs(collection(tournamentRef, "sources"));
  var sourceObj = {};
  sources.forEach((source) => {
    sourceObj[source.data().name] = source.data();
  });
  console.log(sources);
  return sourceObj;
};

const updateSource = async (id, source) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  let sourceRef = doc(collection(tournamentRef, "sources"), source.name);
  await setDoc(sourceRef, source);
};

const deleteSource = async (id, source) => {
  id = id.toString();
  const tournamentRef = doc(db, "tournaments", id);
  let sourceRef = doc(collection(tournamentRef, "sources"), source.name);
  await deleteDoc(sourceRef);
};

const listenForMatchLabelUpdates = (event_id, match_id, callback) => {
  event_id = event_id.toString();
  match_id = match_id.toString();
  const tournamentRef = doc(db, "tournaments", event_id);
  const matchRef = doc(collection(tournamentRef, "matches"), match_id);
  return onSnapshot(matchRef, (doc) => {
    callback(doc.data());
  });
};

const getOrCreateMatchLabels = async (event_id, match_id, data) => {
  event_id = event_id.toString();
  match_id = match_id.toString();
  const tournamentRef = doc(db, "tournaments", event_id);
  const matchRef = doc(collection(tournamentRef, "matches"), match_id);
  let matchDoc = await getDoc(matchRef);
  if (matchDoc.exists()) {
    return matchDoc.data();
  }
  await setDoc(matchRef, data);
  return data;
};

const setMatchLabels = async (event_id, match_id, data) => {
  event_id = event_id.toString();
  match_id = match_id.toString();
  const tournamentRef = doc(db, "tournaments", event_id);
  const matchRef = doc(collection(tournamentRef, "matches"), match_id);
  await setDoc(matchRef, data);
  return data;
};

const getLabelData = async (label) => {
  let type = label.data().type;
  let data = {
    name: label.id,
    type: type,
  };
  if (type == "string" || type == "int") {
    data.default = label.data().default;
    return data;
  } else if (type == "option_select") {
    data.default = label.data().default;
    data.options = [];
    let optionsRef = collection(label.ref, "options");
    let options = await getDocs(optionsRef);
    options.forEach((option) => {
      if (option.exists()) {
        data.options.push(option.id);
        if (option.data().default) {
          data.default = option.id;
        }
      }
    });
    return data;
  }
};

export {
  getLabelsByEventID,
  getAssetsByEventID,
  getAsset,
  updateAsset,
  deleteAsset,
  updateLabel,
  deleteLabel,
  setMatchLabels,
  getParticipantLabels,
  updateParticipantLabel,
  listenForMatchLabelUpdates,
  getOrCreateMatchLabels,
  getSourcesByEventID,
  updateSource,
  deleteSource,
  getTournamentByID,
  updateTournament,
  isTournamentStreaming,
  toggleStreamingTournament,
  listenForTournamentUpdates,
  listenForSourceUpdates,
  listenForEventLabels,
  listenForAssetUpdates,
  cloneTournamentByID,
};

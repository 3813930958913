import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { withCookies, Cookies } from 'react-cookie';
import RegisterForm from './components/RegisterForm';

class Register extends Component {
  constructor(props) {
    super(props);
    if(this.props.user){
      this.props.history.push({ pathname: '/' });
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(userData) {
    console.log(userData)
    this.props.history.push({ pathname: '/login' });
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col md="auto">
              <div className="account__wrapper">
                <div className="account__card">
                  <div className="account__head">
                    <h3 className="account__title">Register for
                      <span className="account__logo"> teacup
                        <span className="account__logo-accent">.gg</span>
                      </span>
                    </h3>
                    <h4 className="account__subhead subhead">The Stream Assistant for Challonge & Smash.gg</h4>
                  </div>
                  <RegisterForm onSuccess={this.handleSubmit}/>
                </div>
              </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withCookies(Register));

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles

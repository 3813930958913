import React, { PureComponent } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import SelectField from "../../../shared/components/form/Select";
import CreatableSelect from "react-select/creatable";

const propTypes = {
  label: PropTypes.objectOf(PropTypes.any),
  updateLabelProperty: PropTypes.func,
  id: PropTypes.string,
};

const defaultProps = {
  label: null,
  updateLabelProperty: null,
  id: null,
};

class Source extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedMapping: undefined,
    };
  }
  componentWillReceiveProps() {
    this.forceUpdate();
  }

  onChange = async (e) => {
    const { updateSource, source } = this.props;
    source[e.target.name] = e.target.value;
    await updateSource(source);
  };

  onChangeOptions = (selectedOptions) => {
    const { id, updateLabelProperty } = this.props;
    updateLabelProperty(id, {
      target: {
        name: "options",
        value: selectedOptions,
      },
    });
  };

  updateLabelValue = (e, mappings) => {
    this.setState({
      selected_mapping: e,
    });
    mappings[e.label] = mappings[e.label] ?? null;
    this.onChange({
      target: {
        name: "mappings",
        value: mappings,
      },
    });
  };

  mappingsField = (source) => {
    const { labels, assets } = this.props;
    console.log(source);
    const label = labels[source.label] ?? undefined;
    const mappings = source.mappings ?? {};
    const mapping = this.state.selected_mapping;
    if (!mapping || source.type !== "image") {
      return null;
    }
    const options = [];
    Object.keys(assets).forEach((option) => {
      options.push({
        label: option,
        value: option,
      });
    });
    return (
      <div className="form__form-group">
        <span className="form__form-group-label">Asset</span>
        <SelectField
          options={options}
          value={
            mappings[mapping.value]
              ? {
                  label: mappings[mapping.value],
                  value: mappings[mapping.value],
                }
              : undefined
          }
          name="mapping"
          placeholder="Asset Name"
          onChange={(e) => {
            mappings[mapping.value] = e.target.value;
            this.onChange({
              target: {
                name: "mappings",
                value: mappings,
              },
            });
          }}
        />
      </div>
    );
  };

  valuesField = (source) => {
    const { labels } = this.props;
    console.log(source);
    const label = labels[source.label] ?? undefined;
    if (source.type !== "image") {
      console.log("hi");
      return null;
    }
    const mappings = source.mappings ?? {};
    if (label.type !== "option_select") {
      const options = Object.keys(mappings).map((mapping) => {
        return {
          label: mapping,
          value: mapping,
        };
      });
      return (
        <div className="form__form-group">
          <span className="form__form-group-label">Label Value</span>
          <CreatableSelect
            options={options}
            name="value"
            value={this.state.selected_mapping}
            placeholder="Label Value"
            onChange={(e) => this.updateLabelValue(e, mappings)}
          />
        </div>
      );
    } else {
      const options = [];
      const value = {
        label: label.default,
        value: label.default,
      };
      label.options.forEach((option) => {
        options.push({
          label: option,
          value: option,
        });
      });
      return (
        <div className="form__form-group">
          <span className="form__form-group-label">Value</span>
          <SelectField
            options={options}
            value={this.state.selected_mapping}
            name="value"
            placeholder="Label Value"
            onChange={(e) => this.updateLabelValue(e.target, mappings)}
          />
        </div>
      );
    }
    return null;
  };

  labelsField = (source) => {
    const { labels } = this.props;
    const updatedLabels = Object.assign(labels, {
      event_name: {
        name: "event_name",
        type: "string",
        default: "",
      },
      tournament_name: {
        name: "tournament_name",
        type: "string",
        default: "",
      },
      round: {
        name: "round",
        type: "string",
        default: "",
      },
      team_name: {
        type: "string",
        name: "team_name",
        default: "",
      },
      score: {
        type: "int",
        name: "score",
        default: 0,
      },
      player_name: {
        type: "string",
        name: "player_name",
        default: "",
      },
    });
    const options = [];
    Object.values(updatedLabels).forEach((label) => {
      options.push({
        label: label.name,
        value: label.name,
      });
    });
    console.log(updatedLabels);
    return (
      <div className="form__form-group">
        <span className="form__form-group-label">Label</span>
        <Select
          value={
            source.label
              ? { label: source.label, value: source.label }
              : undefined
          }
          className="basic-multi-select"
          options={options}
          name="label"
          placeholder="Options"
          onChange={(e) => {
            this.setState({
              selected_mapping: undefined,
            });
            this.onChange({
              target: {
                value: e.value,
                name: "label",
              },
            });
          }}
        />
      </div>
    );
    return null;
  };

  typeField = (source) => {
    const options = {
      string: {
        label: "Text",
        value: "string",
      },
      image: {
        label: "Asset",
        value: "image",
      },
    };
    return (
      <div className="form__form-group">
        <span className="form__form-group-label">Type</span>
        <SelectField
          options={Object.values(options)}
          value={options[source.type]}
          name="type"
          placeholder="Type"
          onChange={(e) => {
            this.setState({
              selected_mapping: undefined,
            });
            this.onChange(e);
          }}
        />
      </div>
    );
  };

  render() {
    const { source } = this.props;
    return (
      <div>
        <form className="form">
          {this.typeField(source)}
          {this.labelsField(source)}
          {this.valuesField(source)}
          {this.mappingsField(source)}
        </form>
      </div>
    );
  }
}

export default Source;

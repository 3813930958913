import React from "react";
import { Col, Container, Row, Jumbotron } from "reactstrap";
import DesktopClientCard from "./components/DesktopClientCard";
import ChromeExtensionCard from "./components/ChromeExtensionCard";

const Download = () => (
  <Container className="dashboard">
    <Row>
      <Col>
        <Jumbotron style={{ background: "transparent" }}>
          <Container>
            <h1 className="display-3">teacup.gg</h1>
            <p className="lead">The Stream Assistant for Smash.gg</p>
          </Container>
        </Jumbotron>
      </Col>
    </Row>
  </Container>
);

export default Download;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import VideoOffOutlineIcon from "mdi-react/VideoOffOutlineIcon";
import VideoWirelessOutlineIcon from "mdi-react/VideoWirelessOutlineIcon";
import PaperclipIcon from "mdi-react/PaperclipIcon";
import EyeIcon from "mdi-react/EyeIcon";
import Match from "./components/Match";
import ParticipantEditor from "./components/ParticipantEditor";
import LabelEditor from "./components/LabelEditor";
import AssetUploader from "./components/AssetUploader";
import SourceEditor from "./components/SourceEditor";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { getEventByID, getBracketUpdateListener } from "../../smash-gg";
import {
  getLabelsByEventID,
  getAssetsByEventID,
  getSourcesByEventID,
  getTournamentByID,
  updateTournament,
  isTournamentStreaming,
  toggleStreamingTournament,
  cloneTournamentByID
} from "../../teacup-gg";

const propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
  history: null,
  match: null,
};

class Bracket extends Component {
  constructor(props) {
    console.log("HERE3");
    super(props);
    const query = new URLSearchParams(props.location.search);
    const clone = query.get("clone");
    const id = this.props.match.params.id;
    let clone_id = null;
    if(clone && id){
      clone_id = clone;
      query.delete('clone');
      props.history.replace({
        search: query.toString(),
      });
    }
    this.state = {
      loading: true,
      tournament: {},
    };
    if (this.props.user) {
      this.state = {
        user: this.props.user,
        loading: this.props.match.params.id != null,
        participants: [],
        stream_queues: {},
        is_streaming: false,
        event_listener: null,
        title: "Load Bracket",
        clone_id
      };
    } else {
      return this.redirectToLogin();
    }
  }

  componentDidMount() {
    const { match, user } = this.props;
    if (user && match.params.id != null) {
      this.verifyBracket(match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const prevMatch = prevProps.match;
    const { match } = this.props;
    if (prevMatch.params.id !== match.params.id) {
      if (match.params.id != null) {
        this.verifyBracket(match.params.id);
      } else {
        this.resetBracket();
      }
    }
  }

  redirectToLogin = () => {
    const locationState = this.props.history.location.state;
    if (!locationState || !locationState.returnAddress) {
      const currentPath = this.props.history.location.pathname;
      this.props.history.push({
        pathname: "/login",
        state: { returnAddress: currentPath },
      });
    }
  };

  getLabels = async (bracket) => {
    const labels = await getLabelsByEventID(bracket);
    this.setState({
      loading: false,
      labels: labels,
    });
  };

  getAssets = async (bracket) => {
    const assets = await getAssetsByEventID(bracket);
    this.setState({
      assets: assets,
    });
  };

  getSources = async (bracket) => {
    const sources = await getSourcesByEventID(bracket);
    this.setState({
      sources: sources,
    });
  };

  addLabel = (label) => {
    const { labels } = this.state;
    labels[label.name] = label;
    this.setState({ labels });
  };

  addAsset = (asset) => {
    const { assets } = this.state;
    assets[asset.name] = asset;
    this.setState({ assets });
  };

  addSource = (source) => {
    const { sources } = this.state;
    sources[source.name] = source;
    this.setState({ sources });
  };

  updateLabelProperty = (id, e) => {
    const { labels } = this.state;
    if (id in labels) {
      const label = labels[id];
      if (e.target.name === "type") {
        if (
          label.type === "option_select" ||
          e.target.value === "option_select"
        ) {
          label.options = [];
        }
        label.type = e.target.value;
        label.default = "";
      } else if (e.target.name === "options") {
        label.options = [];
        let foundDefault = false;
        e.target.value.forEach((option) => {
          label.options.push(option.value);
          if (option.value === label.default) {
            foundDefault = true;
          }
        });
        if (!foundDefault) {
          label.default = "";
          if (label.options.length > 0) {
            const d = label.options[0];
            label.default = d;
          }
        }
      } else if (e.target.name === "default") {
        if (label.type === "option_select") {
          let foundDefault = false;
          label.options.forEach((option) => {
            if (option === e.target.value) {
              foundDefault = true;
            }
          });
          if (foundDefault) {
            label.default = e.target.value;
          }
        } else {
          label.default = e.target.value;
        }
      }
      labels[id] = label;
      this.setState({
        labels,
      });
    }
  };

  updateAssets = (assets) => {
    this.setState({
      assets,
    });
  };

  updateSource = (source) => {
    const { sources } = this.state;
    this.setState(
      (prevState) => {
        let newData = {};
        newData[source.name] = source;
        let newSources = Object.assign(prevState.sources, newData);
        return { newSources };
      },
      () => {
        console.log(this.state);
        this.forceUpdate();
      }
    );
  };

  updateBracket = async (event, tournament, is_streaming) => {
    const { user } = this.props;
    await this.setState({
      bracket: event.id,
      title: event.tournament.name + " - " + event.name,
      tournament,
      event,
      is_streaming,
    });
    await this.getLabels(event.id);
    await this.getAssets(event.id);
    await this.getSources(event.id);
    let eventListener = getBracketUpdateListener(
      event.id,
      user.data.smash_gg_key,
      (data) => {
        console.log(data);
        this.setState({
          bracket: data.event.id,
          title: data.event.tournament.name + " - " + data.event.name,
          participants: data.participants,
          stream_queues: data.stream_queues,
        });
      },
      2000
    );
    if (this.state.eventListener) {
      this.state.eventListener.close();
    }
    await this.setState({
      eventListener: eventListener,
    });
  };

  loadBracket = (urlCode) => {
    const { history } = this.props;
    history.push({ pathname: `/bracket/${urlCode}` });
    this.setState({
      loading: true,
    });
  };

  resetBracket = () => {
    this.setState({
      bracket: null,
      labels: null,
      assets: null,
      sources: null,
      loading: false,
      title: "Load Bracket",
    });
  };

  verifyBracket = async (challongeURL) => {
    console.log(challongeURL);
    const { history } = this.props;
    const { user, clone_id } = this.state;
    try {
      if(clone_id){
        await cloneTournamentByID(clone_id, challongeURL);
      }
      let result = await getEventByID(user.data.smash_gg_key, challongeURL);
      let event = await getTournamentByID(challongeURL);
      let is_streaming = await isTournamentStreaming(
        challongeURL,
        user.data.uid
      );
      console.log(result);
      if (result && result.tournament.admins !== null) {
        await this.updateBracket(result, event, is_streaming);
      } else {
        history.push({ pathname: "/bracket" });
      }
    } catch (e) {
      history.push({ pathname: "/bracket" });
    }
  };

  toggleStreaming = async () => {
    const { user, bracket, stream_queue } = this.state;
    let is_streaming = await toggleStreamingTournament(
      bracket,
      user.data.uid,
      !this.state.is_streaming,
      stream_queue ?? null,
    );
    await this.setState({
      is_streaming,
    });
  };

  updateTournamentData = async (tournament) => {
    const { bracket } = this.state;
    await updateTournament(bracket, tournament);
  };

  updateStreamCode = async (e) => {
    const { tournament } = this.state;
    tournament.stream_code = e.target.value;
    await this.setState({ tournament });
    await this.updateTournamentData(tournament);
  };

  updateCurrentMatch = async (stream_queue, match_id) => {
    const { tournament } = this.state;
    if(stream_queue === null){
      return;
    }
    if(typeof tournament.currentMatch !== 'object'){
      tournament['currentMatch'] = {}
    }
    tournament['currentMatch'][stream_queue] = match_id;
    await this.setState({ tournament, stream_queue });
    await this.updateTournamentData(tournament);
  };

  render() {
    console.log(window.location);
    if (this.state.user != null && !this.state.loading) {
      return (
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <form className="form">
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    <h3>{this.state.title}</h3>
                  </span>
                  {this.state.tournament && <div className="form__form-group-field">
                    <input
                      name="password"
                      component="input"
                      type={"text"}
                      value={this.state.tournament.stream_code ?? undefined}
                      placeholder="Stream Code"
                      onChange={this.updateStreamCode}
                      disabled={this.state.is_streaming ? "disabled" : ""}
                    />
                    <button
                      className={`form__form-group-button${
                        this.state.is_streaming ? " active" : ""
                      }`}
                      disabled={this.state.formDisabled ? "disabled" : ""}
                      onClick={(e) => this.toggleStreaming(e)}
                      type="button"
                    >
                      {this.state.is_streaming ? (
                        <VideoWirelessOutlineIcon />
                      ) : (
                        <VideoOffOutlineIcon />
                      )}
                    </button>
                    {this.state.is_streaming && <CopyToClipboard text={`${window.location.origin}/stream/${this.state.user.data.uid}/${this.state.tournament.stream_code}`}>
                    <button
                      className={`form__form-group-button`}
                      disabled={!this.state.is_streaming ? "disabled" : ""}
                      type="button"
                    >
                      <PaperclipIcon />
                    </button>
                    </CopyToClipboard>}
                  </div>}
                </div>
              </form>
            </Col>
          </Row>
          <Row>
            <Col md>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Current Match</h5>
                    <h5 className="subhead">Update stream match data live!</h5>
                  </div>
                  <Match
                    user={this.state.user}
                    event={this.state.event}
                    updateCurrentMatch={this.updateCurrentMatch}
                    bracket={this.state.bracket}
                    labels={this.state.labels}
                    stream_queues={this.state.stream_queues}
                    loadBracket={this.loadBracket}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Labels</h5>
                    <h5 className="subhead">
                      Add additional labels to participants.
                    </h5>
                  </div>
                  <LabelEditor
                    bracket={this.state.bracket}
                    labels={this.state.labels}
                    updateLabelProperty={this.updateLabelProperty}
                    addLabel={this.addLabel}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Participants</h5>
                    <h5 className="subhead">
                      Add default values for participants.
                    </h5>
                  </div>
                  <ParticipantEditor
                    user={this.state.user}
                    bracket={this.state.bracket}
                    participants={this.state.participants}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Stream Assets</h5>
                    <h5 className="subhead">
                      Add additional images to your stream.
                    </h5>
                  </div>
                  <AssetUploader
                    bracket={this.state.bracket}
                    assets={this.state.assets}
                    updateLabelProperty={this.updateAssets}
                    addAsset={this.addAsset}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Stream Sources</h5>
                    <h5 className="subhead">
                      Choose how to display your labels on stream
                    </h5>
                  </div>
                  <SourceEditor
                    bracket={this.state.bracket}
                    sources={this.state.sources}
                    labels={this.state.labels}
                    assets={this.state.assets}
                    updateSource={this.updateSource}
                    addSource={this.addSource}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Loading</h5>
                  <h5 className="subhead">One moment, please...</h5>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Bracket.propTypes = propTypes;
Bracket.defaultProps = defaultProps;

export default withRouter(Bracket);
